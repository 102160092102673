<template>
  <div>
    <navigation :dark="true" />
    <section id="services-page" class="bg-slate-50 pt-48 pb-72 overflow-hidden relative">
      <div class="w-full px-4 lg:px-0 2xl:max-w-7xl xl:max-w-6xl mx-auto">
        <h1 class="lg:text-6xl text-4xl text-slate-900 text-center">{{ $t('general.notfound') }} ({{ error.statusCode }})</h1>
        <p class="text-black my-16 text-center">{{ $t('general.notfound1') }}</p>
        <p @click="handleError" class="text-black cursor-pointer font-bold my-8 block underline text-center">{{ $t('general.notfound2') }}</p>
      </div>
    </section>
    <bottomcall />
    <footermain />
  </div>
</template>
<script setup lang="ts">
import bottomcall from "../components/sections/general/bottomcaller.vue";
import footermain from "../components/sections/general/footer-main.vue";
import Navigation from "../components/sections/general/navigation.vue";
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})
const handleError = () => clearError({redirect: '/'})
</script>